'use strict';

const MODAL_MIN_WIDTH = 320;
const MODAL_MIN_HEIGHT = 240;
const DEFAULT_MODAL_WIDTH_RATE = 0.4;
const DEFAULT_MODAL_HEIGHT_RATE = 0.5;
const BREAKPOINT_MD = 768;
const BREAKPOINT_SM = 576;

const getModalRates = (modal) => {
  const widthRate = modal.getAttribute("w-rate") || DEFAULT_MODAL_WIDTH_RATE;
  const heightRate = modal.getAttribute("h-rate") || DEFAULT_MODAL_HEIGHT_RATE;
  return {widthRate, heightRate};
};

export const enableResizableModal = function() {
  const modals = document.querySelectorAll('.resizable-modal');
  for (const modal of modals) {
    modal.addEventListener('wheel', (e) => {
      e.preventDefault();
    });
    const {widthRate, heightRate} = getModalRates(modal);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const modalWidth = windowWidth < BREAKPOINT_MD ? windowWidth : windowWidth * widthRate;
    const modalHeight = windowHeight < BREAKPOINT_SM ? windowHeight : windowHeight < BREAKPOINT_MD ? windowHeight * Math.min(heightRate * 1.4, 1.0) : windowHeight * heightRate;
    modal.style.width = `${modalWidth}px`;
    modal.style.height = `${modalHeight}px`;

    const appendHandle = (position, params) => {
      const handle = document.createElement('span')

      handle.classList.add(`${position}-handle`);
      modal.appendChild(handle);

      handle.addEventListener('mousedown', (e) => {
          const dragStartX = e.clientX;
          const dragStartY = e.clientY;
          const rect = modal.getBoundingClientRect();

          const handleMouseMove = (e) => {
            const dx = e.clientX - dragStartX;
            const dy = e.clientY - dragStartY;
            const modalWidth = Math.max(rect.width + params.x * dx, MODAL_MIN_WIDTH);
            const modalHeight = Math.max(rect.height + params.y * dy, MODAL_MIN_HEIGHT);

            if (params.x < 0 && modalWidth > MODAL_MIN_WIDTH) {
              modal.style.left = `${rect.left + dx}px`;
            }

            if (params.x > 0) {
              modal.style.right = `${rect.right + dx}px`;
            }

            if (params.y < 0 && modalHeight > MODAL_MIN_HEIGHT) {
              modal.style.top = `${rect.top + dy}px`;
            }

            if (params.y > 0) {
              modal.style.bottom = `${rect.bottom + dy}px`;
            }

            modal.style.width = `${modalWidth}px`;
            modal.style.height = `${modalHeight}px`;
          };

          document.addEventListener('mousemove', handleMouseMove);

          document.addEventListener('mouseup', (e) => {
          document.removeEventListener('mousemove', handleMouseMove);
          }, {once: true});
      });
    }

    appendHandle('right', {x: 1, y: 0});
    appendHandle('bottom-right', {x: 1, y: 1});
    appendHandle('bottom', {x: 0, y: 1});
    appendHandle('bottom-left', {x: -1, y: 1});
    appendHandle('left', {x: -1, y: 0});
    appendHandle('top-left', {x: -1, y: -1});
    appendHandle('top', {x: 0, y: -1});
    appendHandle('top-right', {x: 1, y: -1});
  }
};
