'use strict';

const SCALE_RATE = 1.2;
const MIN_SCALE = SCALE_RATE**-10;
const MAX_SCALE = SCALE_RATE**10;
const WHEEL_SENSITIVITY = 1.005;
const BOUNDARY_MARGIN = 20;
const ROTATE_UNIT = Math.PI/2;

export const enableRichPicture = function() {
  const wrappers = document.querySelectorAll('.rich-picture');

  for (const wrapper of wrappers) {
    const img = wrapper.querySelector('img');
    if (!img) {
      continue;
    }
    img.style.transformOrigin = 'top left';

    // ユーザの操作による状態
    img.dataset.rotateState = 0;
    img.dataset.scale = 1;
    img.dataset.moveX = 0;
    img.dataset.moveY = 0;
    img.dataset.wrapperLoaded = 'false';

    // ユーザの操作による状態を取得する
    function getStates() {
      const scale = Number(img.dataset.scale);
      const defaultScale = Number(img.dataset.defaultScale);
      const finalScale = scale * defaultScale;
      const rotateState = Number(img.dataset.rotateState);
      const moveX = Number(img.dataset.moveX);
      const moveY = Number(img.dataset.moveY);
      return {scale, defaultScale, finalScale, rotateState, moveX, moveY};
    }

    // 画像とラッパーの座標を取得する
    function getGeometries() {
      const [ww, wh] = [wrapper.clientWidth, wrapper.clientHeight];
      const [iw, ih] = [img.width, img.height];
      return {ww, wh, iw, ih}
    }

    const update = () => {
      // wrapper のサイズを取得する
      const {ww, wh, iw, ih} = getGeometries();

      // 状態を取得する
      const {finalScale, rotateState, moveX, moveY} = getStates();

      // 1. 画像の中心を、左上角(transform-origin)に合わせるように並行移動する
      // 2. scaleを適用して拡大・縮小する
      // 3. 左上角(transform-origin)を中心に回転する
      // 4. ラッパーの中心が画像の中心に一致するように並行移動する + ドラッグでの移動量を加算する
      img.style.transform = `translate(${ww/2+moveX}px,${wh/2+moveY}px) rotate(${rotateState*ROTATE_UNIT}rad) scale(${finalScale}) translate(-${iw/2}px,-${ih/2}px)`;
    }

    const clipScale = (scale) => Math.min(Math.max(scale, MIN_SCALE), MAX_SCALE);

    const setDefaultScale = () => {
      const {ww, wh, iw, ih} = getGeometries();
      if (ww === 0 || wh === 0 || iw === 0 || ih === 0) return;

      img.dataset.defaultScale = Math.min(ww/iw, wh/ih);
    }

    // wrapperのサイズが変化したら再計算する
    const resizeObserver = new ResizeObserver((entries) => {
      setDefaultScale();
      update();
    });
    resizeObserver.observe(wrapper);

    // 画像を読み込んだらラッパーのサイズに合わせてリサイズする
    img.addEventListener('load', () => {
      setDefaultScale();
    });

    wrapper.querySelector('.js-rotate-left-button').addEventListener('click', () => {
      img.dataset.rotateState = Number(img.dataset.rotateState) - 1;
      update();
    });

    wrapper.querySelector('.js-rotate-right-button').addEventListener('click', () => {
      img.dataset.rotateState = Number(img.dataset.rotateState) + 1;
      update();
    });

    wrapper.querySelector('.js-zoom-in-button').addEventListener('click', () => {
      const {scale} = getStates();
      img.dataset.scale = clipScale(scale * SCALE_RATE);
      update();
    });

    wrapper.querySelector('.js-zoom-out-button').addEventListener('click', () => {
      const {scale} = getStates();
      img.dataset.scale = clipScale(scale / SCALE_RATE);
      update();
    });

    wrapper.addEventListener('wheel', (e) => {
      e.preventDefault();
      const {scale} = getStates();
      img.dataset.scale = clipScale(scale * WHEEL_SENSITIVITY ** (- e.deltaY));
      update();
    });

    wrapper.querySelector('.js-reset-button').addEventListener('click', () => {
      img.dataset.moveX = 0;
      img.dataset.moveY = 0;
      img.dataset.rotateState = 0;
      img.dataset.scale = 1;
      update();
    });

    wrapper.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      const {moveX, moveY} = getStates();
      const dragStartX = e.clientX - moveX;
      const dragStartY = e.clientY - moveY;

      const handleMouseMove = (e) => {
        const [dx, dy] = [e.clientX - dragStartX, e.clientY - dragStartY];
        const {ww, wh, iw, ih} = getGeometries();
        const {finalScale, rotateState} = getStates();

        // ラッパー座標系
        // ラッパー左上(0,0)、左下(0,wh)、右下(ww,wh)、右上(ww,0)
        // 画像の初期位置中心の座標は、ラッパーの中心座標(ww/2, wh/2)に一致
        // 移動後のラッパー中心座標(ww/2+dx, wh/2+dy)

        // 移動後の画像左上(dx+ww/2-finalScale*rotiw/2, dy+wh/2-finalScale*rotih/2)
        // 移動後の画像左下(dx+ww/2-finalScale*rotiw/2, dy+wh/2+finalScale*rotih/2)
        // 移動後の画像右下(dx+ww/2+finalScale*rotiw/2, dy+wh/2+finalScale*rotih/2)
        // 移動後の画像右上(dx+ww/2+finalScale*rotiw/2, dy+wh/2-finalScale*rotih/2)

        // ラッパー左上原点の座標系で、画像の左辺のX座標が、ラッパーの右辺のX座標 - BOUNDARY_MARGINよりも大きくならない
        // ラッパー左上原点の座標系で、画像の右辺のX座標が、ラッパーの左辺のX座標 + BOUNDARY_MARGINよりも小さくならない
        // ラッパー左上原点の座標系で、画像の上辺のY座標が、ラッパーの下辺のY座標 - BOUNDARY_MARGINよりも大きくならない
        // ラッパー左上原点の座標系で、画像の下辺のY座標が、ラッパーの上辺のY座標 + BOUNDARY_MARGINよりも小さくならい
        const rad = rotateState * ROTATE_UNIT;

        // 画像の回転を含む最小の長方形(バウンディングボックス)の幅と高さ
        const rotiw = Math.abs(iw * Math.cos(rad)) + Math.abs(ih * Math.sin(rad));
        const rotih = Math.abs(iw * Math.sin(rad)) + Math.abs(ih * Math.cos(rad));

        const maxDx = ww/2 + finalScale * rotiw/2 - BOUNDARY_MARGIN;
        const minDx = - ww/2 - finalScale * rotiw/2 + BOUNDARY_MARGIN;
        const maxDy = wh/2 + finalScale * rotih/2 - BOUNDARY_MARGIN;
        const minDy = - wh/2 - finalScale * rotih/2 + BOUNDARY_MARGIN;
        img.dataset.moveX = Math.max(Math.min(dx, maxDx), minDx);
        img.dataset.moveY = Math.max(Math.min(dy, maxDy), minDy);
        update();
      };

      document.addEventListener('mousemove', handleMouseMove);

      document.addEventListener('mouseup', (e) => {
        img.dataset.dragging = false;
        document.removeEventListener('mousemove', handleMouseMove);
      }, {once: true});
    });
  }
};
